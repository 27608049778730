import React from 'react';
import '../../style.css';
import Navbar from '../Navbar';
import Countdown from '../Countdown'

export default function CoffeeBreakPage() {
  return (
    <>
    <Navbar />
    <Countdown 
        initTime='300' 
        autostart='true'
    />
    <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1275949957&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    </>
  )
}