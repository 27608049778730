import React from 'react';
import '../../style.css';
import MeetingHero from '../MeetingHero';
import Navbar from '../Navbar';

function Home() {
  return (
    <>
      <Navbar /> 
      <MeetingHero />
      {/*<Footer /> */}
    </>
  );
}

export default Home;
