import React from 'react';
import { useTimer } from 'use-timer';
import { Button } from './Button';
import './Countdown.css'

export default function Countdown(props) {
  const { time, start, pause, reset, status, advanceTime } = useTimer({
    endTime: 0,
    initialTime: props.initTime,
    timerType: 'DECREMENTAL',
    autostart: props.autostart
  });

  return (
    <div className='countdown-container'>
      <div className='timer'>  
        <p>
          <span>{Math.floor(time /3600)}h </span>
          <span>{Math.floor(time /60)}m </span>
          <span>{time % 60}</span>s</p>
      </div> 
      {status === 'RUNNING' && <><div className='timer-img'><img src="./images/coffee-64.gif"/></div></>}
      
      <div className='controlButtons'>
        {status === 'PAUSED' && <Button onClick={start} buttonStyle='btn--outline'>Start</Button>}
        {status === 'STOPPED' && <Button onClick={start} buttonStyle='btn--outline'>Reset</Button>}
        {status === 'RUNNING' && <Button onClick={pause}>Pause</Button>}
        <Button onClick={() => advanceTime(-60)} buttonStyle='btn--outline'>+1 min</Button>
        <Button onClick={() => advanceTime(60)} buttonStyle='btn--outline'>-1 min</Button>
      </div>
      
    </div>
  );
};

// https://github.com/thibaultboursier/use-timer