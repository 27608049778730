import React, {useState} from 'react';
import '../../style.css';
import Navbar from '../Navbar';
import YoutubeBackground from 'react-youtube-background'

export default function MeetingStartPage() {

  const [title, setTitle] = useState("Meeting")

  return (
    <>
    <Navbar />
    <YoutubeBackground 
      videoId={"NN1RWBgZ62w"}
      overlay={"rgba(0,0,0,.4)"}
    >
      <div className='content-container'>
      <h1>Please stand-by, almost ready for {title} !</h1>
      </div>
    </YoutubeBackground>
    </>
  )
}