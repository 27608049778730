import React from 'react';
import './style.css';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MeetingStartPage from './components/pages/MeetingStartPage';
import CoffeeBreakPage from './components/pages/CoffeeBreakPage';

export default function App() {
  return (
    <>
      <Router>
        {/* <Navbar /> */}
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/meetingstart" component={MeetingStartPage} />
          <Route path="/break" component={CoffeeBreakPage} />
        </Switch>
      </Router>
    </>
  );
}
