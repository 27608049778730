import React from 'react';
import '../style.css';
import { Button } from './Button';
import './MeetingHero.css';
import './Cards.css';
import CardItem from './CardItem';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='./videos/video-1.mp4' autoPlay loop muted />
      
      <h1>AtMeety</h1>
      <p>Bring atmosphere to meetings!</p>

      <div className="cards">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem
              src="images/countdown-small.gif"
              text="Wait for start"
              label="Set the scene"
              path="/meetingstart"
            />
            <CardItem
              src="images/coffee-64.gif"
              text="Take a short coffee break"
              label="5 min. break"
              path="/break"
            />
            <CardItem
              src="images/mood.gif"
              text="Take a Quick poll"
              label="Interact"
              path="/"
            />
          </ul>
        </div>
     
    </div>
    </div>
  );
}

export default HeroSection;

